<template>
  <Layout tituloPagina="Sistema | Mi Servicio de Internet | Ajustes | Medios de pago">
    <div class="card">
      <div class="card-header justify-content-between d-flex align-items-center">
        <h4 class="card-title">
          <span class="d-none d-sm-block">
            Ajustes de facturación medios de pago
          </span>
          <span class="d-block d-sm-none">
            Medios de pago
          </span>
        </h4>
        <div>
          <div class="dropdown">
            <a
              class="dropdown-toggle"
              href="javascript: void(0);"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <eva-icon
                name="more-horizontal-outline"
                data-eva-width="20"
                data-eva-height="20"
                :class="{
                  'fill-dark': $store.state.layout.layoutMode == 'light',
                  'fill-white': $store.state.layout.layoutMode == 'dark'
                }"
              ></eva-icon>
            </a>
            <ul class="dropdown-menu dropdown-menu-end">
              <li>
                <a
                  href="#"
                  class="dropdown-item"
                  onclick="return false"
                  @click="actualizar()"
                  v-if="!bandera_spinner"
                >
                  <i class="mdi mdi-content-save text-success"></i>
                  Actualizar
                </a>
                <span class="dropdown-item disabled" v-if="bandera_spinner">
                  <i class="mdi mdi-concourse-ci mdi-spin"></i>
                  Actualizar
                </span>
              </li>
              <li>
                <a
                  class="dropdown-item" href="#"
                  onclick="return false;" @click="$router.go(-1)"
                >
                  <i class="mdi mdi-close text-dark"></i>
                  Cerrar
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="card-body">
        <div class="row">
          <!-- correo -->
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
                <h6>
                  Formato de plantilla de medios de pago para el panel de cliente 
                  (<a href="https://miserviciodeinternet.com" target="_blank">https://miserviciodeinternet.com</a>)
                </h6>
              </div>
              <div class="col-md-4 text-right">
                <button
                  type="button"
                  class="btn btn-secondary btn-sm"
                  @click="generarMetodosPagoPanelCliente()"
                >
                  Generar plantilla
                </button>
              </div>
            </div>
            <br>
            <textarea id="metodos_pago_portal_cliente" style="display: none;"></textarea>
          </div>
          <!-- fin  correo -->
        </div>
      </div>
    </div>

    <div class="text-right">
      <div class="btn-group">
        <button class="btn btn-secondary"
          @click="$router.go(-1)">
          <i class="mdi mdi-chevron-left"></i>
          Atras
        </button>
        <button class="btn btn-success" @click="actualizar()" :disabled="bandera_spinner">
          <i
            class="mdi"
            :class="!bandera_spinner ? 'mdi-content-save' : 'mdi-concourse-ci mdi-spin'"
          ></i>
          Actualizar
        </button>
      </div>
    </div>
    <br>
  </Layout>
</template>

<script>
import PlantillaSrv from '@/services/PlantillaSrv.js'
import Layout from "@/views/layouts/main"

const $ = require("jquery")
import "summernote/dist/summernote-lite.min.css"
import "summernote/dist/summernote-lite.min"
export default {
  name: 'Adm3ArgusblackMediosPagoPanelCliente',
  components: { 
    Layout
  },
  data() {
    return {
      plantillas: {
        metodos_pago_portal_cliente: ''
      },
      bandera_spinner: false,
      movil: false
    }
  },

  created:function() {
    var self = this
    if (navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i) ||
        window.innerWidth <= 780
    ) {
      self.movil = true
    }

    this.refrescarPlantilla()
  },
  methods: {
    actualizar() {
      let self = this

      self.bandera_spinner = true

      PlantillaSrv.actualizar('metodos_pago_portal_cliente', self.plantillas.metodos_pago_portal_cliente).then(response => {
        iu.msg.success('Plantilla actualizada')
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar la plantilla'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        self.bandera_spinner = false
      })
    },

    generarMetodosPagoPanelCliente() {
      var self = this
      PlantillaSrv.plantillaPorDefectoJSON('metodos_pago_portal_cliente').then(response => {
        self.plantillas.metodos_pago_portal_cliente = response.data.contenido
        $('#metodos_pago_portal_cliente').summernote('code',self.plantillas.metodos_pago_portal_cliente)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo actualizar'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    refrescarPlantilla() {
      let self = this
      PlantillaSrv.plantillaJSON('metodos_pago_portal_cliente').then(response => {
        self.plantillas.metodos_pago_portal_cliente = response.data.contenido
        $('#metodos_pago_portal_cliente').summernote('code',self.plantillas.metodos_pago_portal_cliente)
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = ''
        }
        mensaje != '' ? iu.msg.error(mensaje) : null
        console.log(error)
      })
    }
  },
  mounted() {
    let self = this   
    //es para el summernote de mensaje de correo
    $(document).ready(function() {
      var toolbar = [
        ['style', ['style', 'bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['table', ['table']],
        ['height', ['height']],
        ['view', ['codeview', 'help']]
      ]

      if (self.movil) {
        toolbar = [
          ['style', ['style', 'bold', 'italic', 'underline']],
          ['fontsize', ['fontsize', 'color']],
          ['para', ['ul', 'paragraph']],
          ['table', ['table', 'height']],
          ['view', ['codeview']]
        ]
      }

      $('#metodos_pago_portal_cliente').summernote({
        height: 800,
        callbacks: {
          onChange: function(contents, $editable) {
            self.plantillas.metodos_pago_portal_cliente = contents
          }
        },
        toolbar: toolbar
      })
      $('.note-editable').css('background-color', '#FFFFFF')
    })
  }
};
</script>

<style>
</style>